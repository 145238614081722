import React, { useEffect } from 'react';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const HungerHeroes = () => {
  const navigate = useNavigate(); // Use useNavigate hook
  // Sample image URLs, replace with your own image URLs
  const images = [
    'https://example.com/image1.jpg',
    'https://example.com/image2.jpg',
    'https://example.com/image3.jpg',
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="HungerHeroes">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
          height: '100%',
          width: '70%',
          padding: '5rem 2rem', // Adjust padding as needed
          margin: '0 auto',
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            style={{
              borderRadius: '50%',
              padding: '2px',
              backgroundColor: '#60ffdd',
              marginRight: '8px',
            }}
            onClick={() => navigate('/')} // Use navigate instead of history.push
          >
            <ArrowBackIcon style={{ color: '#fff' }} />
          </IconButton>
          <Typography variant="body2" style={{ color: '#8892ae' }}>
            Back
          </Typography>
        </Toolbar>
        <br></br>
        <br></br>
        <Typography variant="h3" fontWeight="bold" sx={{ mb: '0.5rem', color: 'primary.main' }}>
          Hunger Heroes
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" marginBottom="0.5rem" color="white">
          Overview:
        </Typography>
        <p style={{ color: 'white' }}>
          Hunger Heroes, a distinguished 501C3 organization, is dedicated to combating food insecurity through the innovative delivery of free meals via a cutting-edge mobile app. Our initiative revolves around three key pillars: engaging participating restaurants, inspiring meal donors, and providing essential nourishment to food-insecure individuals through donated meals.        </p>
        <br></br>
        <br></br>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%', // Use 100% width for flexibility
          }}
        >
          {/* Role */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Role:
            </Typography>
            <Typography variant="subtitle1" color="white">
              UX Designer & Front End Developer
            </Typography>
          </div>
          {/* Date */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Date:
            </Typography>
            <Typography variant="subtitle1" color="white">
              January 2023 - Present
            </Typography>
          </div>
          {/* Skills */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Tools:
            </Typography>
            <Typography variant="subtitle1" color="white">
              Figma, Flutter, Android Studio, Material UI
            </Typography>
          </div>
        </Box>
        <br></br>
        <br></br>
        <div>
          <Typography variant="subtitle1" fontWeight="bold" color="white">
            Prototype:
          </Typography>
          <p style={{ color: 'white' }}>
            Interact with the prototype by clicking anywhere on the screen below. Blue highligthing denotes the component is clickable.
          </p>
        </div>
        <iframe
          style={{ border: '1px solid rgba(0, 0, 0, 0.1)', width: '1000px', height: '800px' }}
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F3Nb70NslfA7GFB1RdzhZr9%2FHunger-Heroes%3Ftype%3Ddesign%26node-id%3D1-1065%26t%3Dx2C8L7KBa0LloOIa-1%26scaling%3Dscale-down%26page-id%3D0%253A1%26mode%3Ddesign"
          allowFullScreen
        ></iframe>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Project Objectives:
        </Typography>
        <p style={{ color: 'white' }}>
          Our mission is to shape unparalleled user experiences for both participating restaurants and meal donors. This endeavor culminated in the meticulous development of a dynamic clickable prototype for the Hunger Heroes mobile app, poised to transform the landscape of food assistance. This prototype was meticulously crafted and presented to our leadership team, signifying a pivotal step towards the app's full realization.
        </p>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Key Contributions:
        </Typography>
        <ul style={{ color: 'white' }}>
          <li style={{ marginBottom: '0.5rem' }}><b>User-Centric Approach:</b>{''}
            Conducted persona mapping sessions, collaborating closely with the project team to intricately define the core users – restaurants and donors. This comprehensive understanding laid the foundation for a user-centric design.</li>
          <br></br>
          <li style={{ marginBottom: '0.5rem' }}><b>Needs Assessment:</b>{''}
            Embarked on "working backwards" sessions and conducted insightful discovery calls with potential app users, including prominent restaurant chains and donors. This facilitated the identification and addressing of specific needs and expectations, ensuring a tailored and impactful solution.</li>
          <br></br>
          <li style={{ marginBottom: '0.5rem' }}><b>Visualizing User Flows:</b>{''}
            Leveraged the power of Miro to meticulously craft and present user flows for each persona. The result was a seamless and intuitive user experience that prioritized the diverse requirements of our target audience.</li>
          <br></br>
          <li style={{ marginBottom: '0.5rem' }}><b>Team Leadership:</b>{''}
            Provided guidance to a dynamic team of four intern developers over a 12-week period, steering their efforts towards the development of innovative app features. The collaboration resulted in a cohesive and robust clickable prototype.</li>
          <br></br>
          <li style={{ marginBottom: '0.5rem' }}><b>Prototyping Excellence:</b>{''}
            Utilized Figma to design and assemble a clickable prototype that encapsulates the essence of Hunger Heroes' mission. This prototype serves as a tangible representation of our vision and was a crucial element in securing support for the app's further development.</li>
          <br></br>
          <li style={{ marginBottom: '0.5rem' }}><b>Engagement and Progress Tracking:</b>{''}
            Facilitated daily stand-up meetings with the product owner, developers, and the project manager. These meetings showcased new features of the prototype, fostering productive discussions and ensuring alignment on the next steps.</li>

        </ul>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Achievements:
        </Typography>
        <p style={{ color: 'white' }}>
          The culmination of these efforts was the presentation of the clickable Figma prototype to the leadership team. This pivotal moment resulted in Hunger Heroes securing vital funding and support for the ongoing development of the application. This achievement propels us forward, ensuring the eventual delivery of the app to customers and marking a significant stride in our mission to combat food insecurity.





        </p>
      </Box>
    </div>
  );
};

export default HungerHeroes;
