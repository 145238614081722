// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './Components/NavigationBar';
import HeroSection from './Components/HeroSection';
import WorkExperience from './Components/WorkExperience';
import About from './Components/AboutSection';
import Certifications from './Components/Certifications';
import Projects from './Components/Projects';
import Footer from './Components/Footer';
import ProjectCards from './Components/ProjectCards';
import AmazonManagedBlockchainQuery from './Components/AmazonManagedBlockchainQuery';
import HungerHeroes from './Components/HungerHeroes';
import EmployerSpot from './Components/EmployerSpot';
import AmazonSustainability from './Components/AmazonSustainability';

function App() {
  return (
    <Router>
      <div className="fade-in">
        <NavigationBar />
        <Routes>
          <Route path="/AmazonManagedBlockchainQuery" element={<AmazonManagedBlockchainQuery />} />
          <Route path="/HungerHeroes" element={<HungerHeroes />} />
          <Route path="/EmployerSpot" element={<EmployerSpot />} />
          <Route path="/AmazonSustainability" element={<AmazonSustainability />} />
          <Route path="/" element={<>
            <HeroSection />
            <About />
            {/* <WorkExperience /> */}
            <ProjectCards />
            <Footer />
          </>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
