import { Box, Typography } from '@mui/material';

const About = () => {
  return (
    <div id="about">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: '100%',
          width: '70%',
          padding: '2rem 2rem 5rem', // Adjust the bottom padding here
          margin: '0 auto', // Remove left margin and center horizontally
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold', mb: '1rem' }}>
          About
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'medium', mb: '1rem' }}>
          Hello World! My name is Sumeeyah Haq and I am a front-end developer and user experience designer at Amazon Web Services. I graduated form California State University Northridge in May of 2021 with a Bachelors of Science in Computer Information Technology.
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'medium', mb: '1rem' }}>
          As the link between applications development and design, I spearhead various design initiatives, guiding projects from conception to release to deliver high-quality user experiences. I work on crafting comprehensive user flows, encompassing both low and high fidelity wireframes, and generating prototypes as required. On a daily basis, I collaborate with cross-functional teams comprising product managers, researchers, and developers to implement impactful product experiences on a global scale. My approach involves presenting diverse solutions tailored to user requirements, business objectives, and technical limitations for each product.
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'medium', mb: '1rem' }}>
          My true passion lies in collaborating with teams to dissect complex issues and craft innovative, universally accessible solutions. I enjoy educating clients on the advantages of building a robust user experience while crafting designs that align with their business and technical prerequisites.
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'medium', mb: '1rem' }}>
          During my Amazon Web Services internship, which was extended three times, I thrived in immersing myself in customer needs, analyzing their challenges, and inovating tailored solutions. My greatest satisfaction comes from delivering results that empower customers to expand and scale up. As a fervent learner, I actively pursue opportunities to enhance my skill set, enabling me to cater to users' diverse needs across various industries. I've had the privilege of working with clients in fields such as healthcare, blockchain, non-profit, aerospace, and education, to name a few.
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'medium', mb: '1rem' }}>
          Here are some technologies and areas I’ve been diving deep into recently:
        </Typography>
        <Box sx={{ fontWeight: 'medium', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', color: 'secondary.main' }}>
          <Box sx={{ fontWeight: 'medium', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '45%', color: 'white' }}>
            <ul>
              <li>AWS Cloud</li>
              <li>Wireframing and Prototyping</li>
              <li>UI/UX Design</li>
            </ul>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '45%', color: 'white' }}>
            <ul>
              <li>Product Development</li>
              <li>Figma</li>
              <li>ReactJS</li>
            </ul>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default About;
