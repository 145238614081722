import React, { useEffect } from 'react';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AmazonManagedBlockchainQuery = () => {
  const navigate = useNavigate(); // Use useNavigate hook

  // Sample image URLs, replace with your own image URLs
  const images = [
    'https://example.com/image1.jpg',
    'https://example.com/image2.jpg',
    'https://example.com/image3.jpg',
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="AmazonManagedBlockchainQuery">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
          height: '100%',
          width: '70%',
          padding: '5rem 2rem', // Adjust padding as needed
          margin: '0 auto',
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            style={{
              borderRadius: '50%',
              padding: '2px',
              backgroundColor: '#60ffdd',
              marginRight: '8px',
            }}
            onClick={() => navigate('/')} // Use navigate instead of history.push
          >
            <ArrowBackIcon style={{ color: '#fff' }} />
          </IconButton>
          <Typography variant="body2" style={{ color: '#8892ae' }}>
            Back
          </Typography>
        </Toolbar>
        <br></br>
        <br></br>
        <Typography variant="h3" fontWeight="bold" sx={{ mb: '0.5rem', color: 'primary.main' }}>
          Amazon Managed Blockchain Query
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Overview:
        </Typography>
        <p style={{ color: 'white' }}>
          Amazon Managed Blockchain Access and Query stands at the forefront of simplifying access to blockchain data, providing an efficient and cost-effective solution that significantly enhances the digital asset user experience. Recognizing the burgeoning need for user-friendly products to empower investors in managing their digital assets, including cryptocurrencies, our products prioritize accessibility and essential data, such as asset balances and transaction histories.
        </p>
        <br></br>
        <br></br>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%', // Use 100% width for flexibility
          }}
        >
          {/* Role */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Role:
            </Typography>
            <Typography variant="subtitle1" color="white">
              UX Designer & Front End Developer
            </Typography>
          </div>
          {/* Date */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Date:
            </Typography>
            <Typography variant="subtitle1" color="white">
              January 2023 - May 2023
            </Typography>
          </div>
          {/* Skills */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Tools:
            </Typography>
            <Typography variant="subtitle1" color="white">
              Figma, React, Material UI
            </Typography>
          </div>
        </Box>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Prototpye:
        </Typography>
        <p style={{ color: 'white' }}>
          Interact with the prototype by clicking anywhere on the screen below. Blue highligthing denotes the component is clickable.
        </p>
        <iframe
          style={{ border: '1px solid rgba(0, 0, 0, 0.1)', width: '1000px', height: '800px' }}
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FOcJIyqFJ8pxjYGoEXdi9KZ%2FAmazon-Managed-Blockchain-Query%3Ftype%3Ddesign%26node-id%3D0-5%26t%3Di52FMogUZtZRba1s-1%26scaling%3Dscale-down%26page-id%3D0%253A1%26starting-point-node-id%3D0%253A5%26mode%3Ddesign"
          allowFullScreen
        ></iframe>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          AWS Blog:
        </Typography>
        <p style={{ color: 'white' }}>
          In this blog, users can understand the product and explore the creation of a web-based cryptocurrency wallet tracker using Amazon Managed Blockchain Query.
          <br></br>
          <br></br>
          <a href="https://aws.amazon.com/blogs/database/build-a-web-based-cryptocurrency-wallet-tracker-with-amazon-managed-blockchain-access-and-query/
/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
            https://aws.amazon.com/blogs/database/build-a-web-based-cryptocurrency-wallet-tracker-with-amazon-managed-blockchain-access-and-query/

          </a>
        </p>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          AWS Samples GitHub:
        </Typography>
        <p style={{ color: 'white' }}>
          An AWS Samples repository with a web application that utilizes Amazon Managed Blockchain Query to create a digital asset portfolio tracker for Bitcoin and Ethereum. Includes the CDK project (infrastructure as code) required to deploy the AWS services and host the web app.
          <br></br>
          <br></br>
          <a href="https://github.com/aws-samples/digital-asset-portfolio-tracker
/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
            https://github.com/aws-samples/digital-asset-portfolio-tracker
          </a>
        </p>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Project Objectives
        </Typography>
        <p style={{ color: 'white' }}>

          Our goal was to design and create a compelling proof of concept for an API, showcasing the immense potential of the Amazon Managed Blockchain Query service. This involved crafting user-centric prototypes for a desktop version of the NFT/Crypto wallet application using Figma, ensuring a seamless and engaging user experience.</p>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Key Contributions:
        </Typography>
        <ul style={{ color: 'white' }}>
          <li style={{ marginBottom: '0.5rem' }}><b>User-Centric Prototyping:</b>{" "}
            Developed user-centric prototypes for the desktop version of an NFT/Crypto wallet application using Figma. These prototypes were meticulously designed to ensure a seamless and engaging user experience, placing a premium on accessibility and functionality.</li>
          <br></br>

          <li style={{ marginBottom: '0.5rem' }}><b>Daily Collaboration and Refinement:</b>{" "}
            Facilitated daily stand-up meetings with the Amazon Managed Blockchain team to share, refine, and iterate on prototype features and design. This collaborative approach ensured that the evolving product consistently met the expectations of both users and stakeholders.</li>
          <br></br>

          <li style={{ marginBottom: '0.5rem' }}><b>Collaboration with AWS Managed Blockchain Service Team:</b>{" "}
            Worked closely with the AWS Managed Blockchain service team to define the product's vision and branding. This collaboration was instrumental in creating a cohesive and impactful user experience, aligning the product with the broader AWS ecosystem..</li>
          <br></br>

          <li style={{ marginBottom: '0.5rem' }}><b>Development of User-Friendly Application:</b>{" "}
            Transformed the approved prototype into a user-friendly and functional React web application. This application leverages the Amazon Managed Blockchain Query service to create a digital asset portfolio tracker for prominent cryptocurrencies like Bitcoin and Ethereum.

          </li>
        </ul>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Achievements:
        </Typography>
        <p style={{ color: 'white' }}>
          The culmination of these efforts resulted in the public accessibility of Amazon Managed Blockchain Query, now an integral part of the AWS Samples GitHub code repository for users. This includes the CDK project for infrastructure as code, necessary for deploying AWS services and hosting the web application. Our achievement ensures that investors and users can seamlessly leverage the power of Amazon Managed Blockchain Query, marking a significant milestone in providing accessible and user-friendly solutions for managing digital assets in the dynamic world of cryptocurrencies.

        </p>
        <br></br>
        <br></br>
      </Box>
    </div>
  );
};

export default AmazonManagedBlockchainQuery;
