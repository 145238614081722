import React, { useEffect } from 'react';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const EmployerSpot = () => {
  const navigate = useNavigate(); // Use useNavigate hook
  // Sample image URLs, replace with your own image URLs
  const images = [
    'https://example.com/image1.jpg',
    'https://example.com/image2.jpg',
    'https://example.com/image3.jpg',
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="EmployerSpot">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
          height: '100%',
          width: '70%',
          padding: '5rem 2rem', // Adjust padding as needed
          margin: '0 auto',
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            style={{
              borderRadius: '50%',
              padding: '2px',
              backgroundColor: '#60ffdd',
              marginRight: '8px',
            }}
            onClick={() => navigate('/')} // Use navigate instead of history.push
          >
            <ArrowBackIcon style={{ color: '#fff' }} />
          </IconButton>
          <Typography variant="body2" style={{ color: '#8892ae' }}>
            Back
          </Typography>
        </Toolbar>
        <br></br>
        <br></br>
        <Typography variant="h3" fontWeight="bold" sx={{ mb: '0.5rem', color: 'primary.main' }}>
          Employer Spot
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Overview:
        </Typography>
        <p style={{ color: 'white' }}>
          In response to the unengaged state of approximately 178,000 Small and Mid-Sized Business (SMB) customers in the Americas region, AWS recognized a need for a proactive approach to engage these customers. The objective was to make these SMBs aware of the valuable return on investment (ROI) associated with early career learners and the added benefits of AWS Learn. This critical information is essential for SMB customers, as it offers a pipeline of diverse cloud talent to help address their skills gap.
        </p>
        <br></br>
        <br></br>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%', // Use 100% width for flexibility
          }}
        >
          {/* Role */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Role:
            </Typography>
            <Typography variant="subtitle1" color="white">
              UX Designer & Front End Developer
            </Typography>
          </div>
          {/* Date */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Date:
            </Typography>
            <Typography variant="subtitle1" color="white">
              June 2021 - December 2021
            </Typography>
          </div>
          {/* Skills */}
          <div>
            <Typography variant="subtitle1" fontWeight="bold" color="white">
              Tools:
            </Typography>
            <Typography variant="subtitle1" color="white">
              Figma, Amplify, Amazon QuickSight, Material UI
            </Typography>
          </div>
        </Box>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Live Website:
        </Typography>
        <p style={{ color: 'white' }}>
          <a href="https://master.d36ioia671ej6p.amplifyapp.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
            https://master.d36ioia671ej6p.amplifyapp.com/
          </a>
        </p>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          High Fidelity Wireframe:
        </Typography>
        <p style={{ color: 'white' }}>
          Interact with the wireframe by zooming into any part on the screen below.
        </p>
        <iframe
          style={{ border: '1px solid rgba(0, 0, 0, 0.1)', width: '1000px', height: '800px' }}
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FdoVcOScz1rTErXij7FRpbJ%2FTechU-Employer-Spot%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DfBvwRTOaOzlYd5hq-1"
          allowFullScreen
        ></iframe>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Project Objectives:
        </Typography>
        <p style={{ color: 'white' }}>
          The goal was to design a comprehensive web portal tailored for SMB customers and recruiters within these businesses. The emphasis was on illuminating AWS's three main education program offerings, connecting SMBs with early career cloud talent. This involved conducting user interviews and research to understand recruiters' needs, developing a clickable desktop application prototype using Figma, and implementing data dashboards for feedback collection.
        </p>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Key Contributions:
        </Typography>
        <ul style={{ color: 'white' }}>
          <li style={{ marginBottom: '0.5rem' }}><b>User-Centric Design:</b>{" "}
            Conducted extensive user interviews and research to gain a deep understanding of recruiters' needs and expectations when hiring and developing early career talent. This informed the design of a web portal that caters specifically to these requirements.
          </li>
          <br></br>
          <li style={{ marginBottom: '0.5rem' }}><b>Clickable Prototype Development:</b>{" "}
            Developed a user-friendly and informative clickable desktop application prototype for the web portal using Figma. This prototype serves as a visual representation of the portal's functionality, ensuring a seamless user experience.</li>
          <br></br>

          <li style={{ marginBottom: '0.5rem' }}><b>Collaboration with AWS Employer Engagement Team:</b>{" "}
            Collaborated closely with the AWS Employer Engagement team to establish branding and define the product vision for the web portal. This partnership ensured alignment with AWS's overarching objectives and maintained a cohesive brand presence.</li>
          <br></br>

          <li style={{ marginBottom: '0.5rem' }}><b>Web Portal Implementation:</b>{" "}
            Utilized ReactJS and styled-components to design and craft a functional web portal, providing guidance to users on how to effectively hire and develop early cloud talent. The implementation emphasized creativity, critical thinking, and user-centric design principles.</li>
          <br></br>

          <li style={{ marginBottom: '0.5rem' }}><b>Data Collection and Dashboard Implementation:</b>{" "}
            Implemented dashboards on the backend to collect data on user visits and gather feedback. Amazon QuickSight and Amazon Pinpoint were instrumental in visually displaying the collected feedback data, providing valuable insights for ongoing improvements.

            .</li>

        </ul>
        <br></br>
        <br></br>
        <Typography variant="subtitle1" fontWeight="bold" color="white">
          Achievements:
        </Typography>
        <p style={{ color: 'white' }}>
          The presentation of the web portal marked a significant milestone, capturing the attention of 178,000 Small and Mid-Sized Business (SMB) customers. The clickable prototype was seamlessly transitioned to the AWS Employer Engagement team, who are actively working on a go-to-market strategy. This achievement underscores the success of the initiative, demonstrating tangible results in engaging SMBs, fostering education, and promoting the hiring of early career cloud talent.







        </p>
      </Box>
    </div>
  );
};

export default EmployerSpot;
