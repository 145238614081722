import { createTheme } from '@mui/material/styles';

// Create a theme instance with updated colors.
const theme = createTheme({
  typography: {
    fontFamily: [
      'Quicksand',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#60ffdd', // Neon Blue
    },
    secondary: {
      main: '#ccd6f5', // Light Text
    },
    text: {
      primary: '#8892ae', // Dark Text
      secondary: '#fff'
    },
    background: {
      default: '#0a192e', // Dark Blue
    },
  },
});

export default theme;
