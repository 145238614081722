import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';

const HeroSection = () => {
  const resumePath = 'https://sumeeyahhaqresume.s3.amazonaws.com/sumeeyah-haq-resume.pdf';

  const handleDownloadResume = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = resumePath;
    link.target = '_blank'; // Open the link in a new tab
    link.rel = 'noopener noreferrer'; // Security best practice for opening in a new tab
    link.click();
  };

  const handleAboutClick = () => {
    // Scroll to the "About" section
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id="home">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '50%',
          padding: '10rem 2rem 15rem', // Decrease top and bottom padding
          margin: '0 auto', // Remove left margin and center horizontally
          '@media (max-width: 900px)': {
            width: '100%',
            padding: '10rem 1rem 14rem'
          },
        }}
      >
        <Typography variant="h5" sx={{ color: 'secondary.main', fontWeight: 'bold', mb: '2rem', textAlign: 'center' }}>
          Hello World, I&apos;m
        </Typography>
        <Typography variant="h2" sx={{ color: 'primary.main', fontWeight: 'bold', mb: '2rem', textAlign: 'center' }}>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString("Sumeeyah Haq")
                .pauseFor(1000)
                .start();
            }}
          />
        </Typography>
        <Typography variant="body1" sx={{
          variant: 'subtitle1',
          color: 'white',
          mb: '0.5rem',
          textAlign: 'center',
        }}>
          As a Los Angeles-based front-end developer and user experience designer, I specialize in crafting intuitive and innovative global products, collaborating closely with cross-functional teams to ensure industry-leading user experiences. Bridging the gap between applications development and design, I spearhead various initiatives to deliver high-quality user experiences from conception to release.        </Typography>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
          <Button variant="outlined" color="primary" onClick={handleDownloadResume} sx={{ marginRight: '5rem' }}>
            Resume
          </Button>
          <Button variant="outlined" color="primary" onClick={handleAboutClick}>
            About
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default HeroSection;
