import React, { useEffect } from 'react';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AmazonSustainability = () => {
    const navigate = useNavigate(); // Use useNavigate hook

    // Sample image URLs, replace with your own image URLs
    const images = [
        'https://example.com/image1.jpg',
        'https://example.com/image2.jpg',
        'https://example.com/image3.jpg',
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id="AmazonManagedBlockchainQuery">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'left',
                    height: '100%',
                    width: '70%',
                    padding: '5rem 2rem', // Adjust padding as needed
                    margin: '0 auto',
                    '@media (max-width: 600px)': {
                        width: '100%',
                    },
                }}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        style={{
                            borderRadius: '50%',
                            padding: '2px',
                            backgroundColor: '#60ffdd',
                            marginRight: '8px',
                        }}
                        onClick={() => navigate('/')} // Use navigate instead of history.push
                    >
                        <ArrowBackIcon style={{ color: '#fff' }} />
                    </IconButton>
                    <Typography variant="body2" style={{ color: '#8892ae' }}>
                        Back
                    </Typography>
                </Toolbar>
                <br></br>
                <br></br>
                <Typography variant="h3" fontWeight="bold" sx={{ mb: '0.5rem', color: 'primary.main' }}>
                    Amazon Sustainability Decarbonization Model
                </Typography>
                <br></br>
                <br></br>
                <Typography variant="subtitle1" fontWeight="bold" color="white">
                    Overview:
                </Typography>
                <p style={{ color: 'white' }}>
                    The Amazon Sustainability Exchange offers a curated selection of tools and resources aimed at inspiring and accelerating sustainability initiatives and includes playbooks, tools for sustainability reporting and goal setting, as well as resources to help suppliers build, measure, and act on their sustainability plans.

                    Embedded within the Amazon Sustainability Exchange is the Sustainability Decarbonization Model tool I had the privilege to work on. This tool enables users to choose from various models (such as paper, plastics, concrete, steel, fuels, and hydrogen), establish their baseline, and make informed decarbonization decisions to assess their impact on carbon intensity.                 </p>
                <br></br>
                <br></br>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%', // Use 100% width for flexibility
                    }}
                >
                    {/* Role */}
                    <div>
                        <Typography variant="subtitle1" fontWeight="bold" color="white">
                            Role:
                        </Typography>
                        <Typography variant="subtitle1" color="white">
                            UX Designer & Front End Developer
                        </Typography>
                    </div>
                    {/* Date */}
                    <div>
                        <Typography variant="subtitle1" fontWeight="bold" color="white">
                            Date:
                        </Typography>
                        <Typography variant="subtitle1" color="white">
                            March 2024 - Present
                        </Typography>
                    </div>
                    {/* Skills */}
                    <div>
                        <Typography variant="subtitle1" fontWeight="bold" color="white">
                            Tools:
                        </Typography>
                        <Typography variant="subtitle1" color="white">
                            Figma, React
                        </Typography>
                    </div>
                </Box>
                <br></br>
                <br></br>

                <Typography variant="subtitle1" fontWeight="bold" color="white">
                    The Amazon Sustainability Exchange:
                </Typography>
                <p style={{ color: 'white' }}>
                    A tool for choosing models (e.g., paper, plastics), setting a baseline, and making informed decarbonization decisions to assess carbon intensity.
                    <br></br>
                    <br></br>
                    <a href="https://exchange.aboutamazon.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
                        https://exchange.aboutamazon.com/decarbonization-models
                    </a>
                </p>
                <br></br>
                <Typography variant="subtitle1" fontWeight="bold" color="white">
                    Prototpye:
                </Typography>
                <p style={{ color: 'white' }}>
                    Interact with the prototype by clicking anywhere on the screen below.
                </p>
                <iframe
                    style={{ border: '1px solid rgba(0, 0, 0, 0.1)', width: '1000px', height: '800px' }}
                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F4inRoROkEFjSsqskcca1lH%2FAmazon-Decarbonization-Pathway-Model%3Fnode-id%3D1-1529%26t%3DQq0WyvQ75OAK8Fie-1"></iframe>
                <br></br>

                <Typography variant="subtitle1" fontWeight="bold" color="white">
                    Problem:
                </Typography>
                <p style={{ color: 'white' }}>

                    Identifying effective decarbonization pathways amidst technological and business constraints is challenging. Traditional life cycle assessments (LCAs) lack the agility needed to quickly assess new technologies and optimize carbon reduction solutions in transportation fuel production.
                </p>
                <br></br>
                <br></br>

                <Typography variant="subtitle1" fontWeight="bold" color="white">
                    Goal:
                </Typography>
                <ul style={{ color: 'white' }}>
                    <li style={{ marginBottom: '0.5rem' }}>
                        Recommend business, technology, and supplier-focused solutions to enhance the carbon abatement potential of renewable diesel (RD) and sustainable aviation fuel (SAF).
                    </li>
                    <br></br>

                    <li style={{ marginBottom: '0.5rem' }}>
                        Improve stakeholder decision-making in the decarbonization of transportation fuels.
                    </li>
                    <br></br>

                    <li style={{ marginBottom: '0.5rem' }}>
                        Increase knowledge and awareness of decarbonization technologies, policies, and strategies.
                    </li>
                    <br></br>

                    <li style={{ marginBottom: '0.5rem' }}>
                        Optimize supply chains for sustainability, reduce carbon emissions, and enhance resilience to climate-related disruptions.
                    </li>
                </ul>
                <br></br>
                <br></br>


                <Typography variant="subtitle1" fontWeight="bold" color="white">
                    Solution:
                </Typography>
                <p style={{ color: 'white' }}>

                    The Sustainability Decarbonization Application is a static, responsive website designed to evaluate and identify decarbonization choices and production pathways across six models: cement, fuels, plastics, paper, hydrogen, and steel. The application uses JavaScript Object Notation (JSON) to handle raw model data, which is embedded into the front end to ensure responsiveness without back-end integration or a database store.

                </p>
                <br></br>
                <br></br>
                <Typography variant="subtitle1" fontWeight="bold" color="white">
                    Key Contributions:
                </Typography>
                <ul style={{ color: 'white' }}>
                    <li style={{ marginBottom: '0.5rem' }}><b>User-Centric Prototyping:</b>{" "}
                        Developed user-centric prototypes for the desktop version of the tool using Figma, ensuring a seamless and engaging user experience.</li>
                    <br></br>

                    <li style={{ marginBottom: '0.5rem' }}><b>Daily Collaboration and Refinement:</b>{" "}
                        Facilitated daily stand-up meetings with the Sustainability team and research scientists to refine and iterate on prototype features and design, ensuring alignment with user and stakeholder expectations.
                    </li>                    <br></br>

                    <li style={{ marginBottom: '0.5rem' }}><b>Collaboration with Research Scientists:

                    </b>{" "}
                        Worked closely with research scientists to convert their data and vision into the tool. This included interpreting research data, terms, definitions, and carbon intensity pathways. Regular stand-ups and design demos ensured the product met their vision and requirements.
                    </li>
                    <br></br>

                    <li style={{ marginBottom: '0.5rem' }}><b>Development of User-Friendly Application:</b>{" "}
                        Transformed the approved prototype into a functional React web application, providing users with a comprehensive view of available decarbonization options.
                    </li>
                </ul>
                <br></br>
                <br></br>
                <Typography variant="subtitle1" fontWeight="bold" color="white">
                    Achievements:
                </Typography>
                <p style={{ color: 'white' }}>
                    The project was showcased by CEO Andy Jassy and VP of Sustainability Kira Hurst in the About Amazon newsletter, making it available for public use. Ongoing collaboration with research scientists and the software engineering team is focused on adding more models to the tool and enhancing its accessibility and design.
                </p>
                <br></br>
                <br></br>
            </Box>
        </div>
    );
};

export default AmazonSustainability;
