import React from 'react';
import { Box, Typography } from '@mui/material';
import theme from '../Theme';
import { Link } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/Folder';
import GitHubIcon from '@mui/icons-material/GitHub';
import { FaFigma } from 'react-icons/fa';

const ProjectCards = () => {
  return (
    <div id="projects">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'left',
          height: '100%',
          width: '70%',
          padding: '5rem 2rem',
          margin: '0 auto',
          '@media (max-width: 600px)': {
            width: '100%',
            padding: '2rem 1rem',
          },
        }}
      >
        <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold', marginBottom: '1rem', textAlign: 'left' }}>
          Projects
        </Typography>
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'medium', mb: '2rem' }}>
          Click on any of the cards below to view details about each project.
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '1rem', // Adjusted spacing
            justifyItems: 'center',
            '@media (min-width: 600px)': {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
          }}
        >

          {/* Card 0 */}
          <Link to="/AmazonSustainability" style={{ textDecoration: 'none' }}>
            <Box
              sx={{
                backgroundColor: '#15223E',
                borderRadius: '10px',
                padding: '1.5rem',
                height: '350px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'left',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#233653',
                },
              }}
            >
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary, fontWeight: 'bold', marginBottom: '1rem' }}>
                Amazon Sustainability Decarbonization Model
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'medium', marginBottom: '0.5rem', fontSize: '15px' }}>
                An Amazon Sustainability tool for choosing models (e.g., paper, plastics), setting a baseline, and making informed decarbonization decisions to assess carbon intensity.             </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'regular', fontSize: '13px' }}>
                Figma, React
              </Typography>
            </Box>
          </Link>

          {/* Card 1 */}
          <Link to="/AmazonManagedBlockchainQuery" style={{ textDecoration: 'none' }}>
            <Box
              sx={{
                backgroundColor: '#15223E',
                borderRadius: '10px',
                padding: '1.5rem',
                height: '350px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'left',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#233653',
                },
              }}
            >
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}>
                Amazon Managed Blockchain Query
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'medium', marginBottom: '0.5rem', fontSize: '15px' }}>
                Unlocking a seamless and cost-effective digital asset management experience, making cryptocurrency investments more user-friendly.
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'regular', fontSize: '13px' }}>
                Figma, ReactJS, Material UI
              </Typography>
            </Box>
          </Link>

          {/* Card 2 */}
          <Link to="/HungerHeroes" style={{ textDecoration: 'none' }}>
            <Box
              sx={{
                backgroundColor: '#15223E',
                borderRadius: '10px',
                padding: '1.5rem',
                height: '350px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'left',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#233653',
                },
              }}
            >
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary, fontWeight: 'bold', marginBottom: '1rem' }}>
                Hunger Heroes
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'medium', marginBottom: '0.5rem', fontSize: '15px' }}>
                A 501(c)(3) nonprofit fighting food insecurity through a mobile app, uniting partner restaurants, donors, and those in need.
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'regular', fontSize: '13px' }}>
                Figma, Flutter, Android Studio, Material UI
              </Typography>
            </Box>
          </Link>

          {/* Card 3 */}
          <Link to="/EmployerSpot" style={{ textDecoration: 'none' }}>
            <Box
              sx={{
                backgroundColor: '#15223E',
                borderRadius: '10px',
                padding: '1.5rem',
                height: '350px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'left',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#233653',
                },
              }}
            >
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary, fontWeight: 'bold', marginBottom: '1rem' }}>
                Employer Spot
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'medium', marginBottom: '0.5rem', fontSize: '15px' }}>
                An AWS TechU project targeting 178,000 SMB customers to raise awareness on early career ROI and AWS Learn benefits to bridge the skills gap.
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'regular', fontSize: '13px' }}>
                Figma, Amplify, Amazon Lex, Amazon QuickSight, Amazon Material UI
              </Typography>
            </Box>
          </Link>

          {/* Card 4
          <Link to="/AlexaSkillsChallenge" style={{ textDecoration: 'none' }}>
            <Box
              sx={{
                backgroundColor: '#15223E',
                borderRadius: '10px',
                padding: '1.5rem',
                height: '350px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'left',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#233653',
                },
              }}
            >
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}>
                Alexa Skills Challenge
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'medium', marginBottom: '0.5rem', fontSize: '15px' }}>
                A year-round initiative: Igniting innovation and engagement for employees, fostering creativity, teamwork, and skills development in a fun summer setting for kids.
              </Typography>
              <Typography sx={{ color: theme.palette.text.secondary, fontWeight: 'regular', fontSize: '13px' }}>
                Alexa, AWS, Product Management
              </Typography>
            </Box>
          </Link> */}

        </Box>
      </Box>
    </div>
  );
};

export default ProjectCards;
