import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        backgroundColor: '#0a192e', // Dark Blue
        color: '#fff', // White
        py: 5, // Vertical padding
        textAlign: 'center',
      }}
    >
      <Typography variant="body2" color={'#ccd6f5'}>
        Designed and Created by Sumeeyah Haq
      </Typography>
      <Typography variant="body2" color={'#ccd6f5'}>
        &copy; {currentYear} All Rights Reserved
      </Typography>
    </Box>
  );
};

export default Footer;
