import { Box, ThemeProvider } from '@mui/material';
import { Email, LinkedIn } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Link as ScrollLink } from 'react-scroll';

const theme = createTheme({
  typography: {
    fontFamily: ['Quicksand', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#60ffdd', // Neon Blue
    },
    secondary: {
      main: '#ccd6f5', // Light Text
    },
    text: {
      primary: '#8892ae', // Dark Text
      secondary: '#fff',
    },
    background: {
      default: '#0a192e', // Dark Blue
    },
  },
});

export default function BottomNav() {
  const isMobile = useMediaQuery('(max-width:900px)');
  if (isMobile) {
    return null;
  }

  const handleEmailClick = () => {
    window.location.href = 'mailto:sumeeyahhaq@gmail.com';
  };

  const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/in/sumeeyahhaq', '_blank');
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: 'fixed', bottom: '90px', right: '50px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'right' }}>
            <li>
              <ScrollLink
                to="home"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                style={{ color: '#60ffdd', fontSize: '14px' }} // Set color to #60ffdd and font size to 14px
                className="scroll-link"
              >
                /home
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="about"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                style={{ color: '#60ffdd', fontSize: '14px' }} // Set color to #60ffdd and font size to 14px
                className="scroll-link"
              >
                /about
              </ScrollLink>
            </li>
            {/* <li>
              <ScrollLink
                to="skills"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                style={{ color: '#60ffdd', fontSize: '14px' }} // Set color to #60ffdd and font size to 14px
                className="scroll-link"
              >
                /skills
              </ScrollLink>
            </li> */}
            {/*<li>
              <ScrollLink
                to="experience"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                style={{ color: '#60ffdd', fontSize: '14px' }} // Set color to #60ffdd and font size to 14px
                className="scroll-link"
              >
                /experience
              </ScrollLink>
            </li>*/}
            <li>
              <ScrollLink
                to="projects"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                style={{ color: '#60ffdd', fontSize: '14px' }} // Set color to #60ffdd and font size to 14px
                className="scroll-link"
              >
                /projects
              </ScrollLink>
            </li>
            {/* <li>
              <ScrollLink
                to="certifications"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                style={{ color: '#60ffdd', fontSize: '14px' }} // Set color to #60ffdd and font size to 14px
                className="scroll-link"
              >
                /certifications
              </ScrollLink>
            </li> */}
          </ul>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Email
              sx={{ ml: 3.5, color: 'primary.main', fontSize: 20, mr: 2, cursor: 'pointer', '&:hover': { color: 'secondary.main' } }}
              onClick={handleEmailClick}
            />
            <LinkedIn
              sx={{ color: 'primary.main', fontSize: 20, cursor: 'pointer', '&:hover': { color: 'secondary.main' } }}
              onClick={handleLinkedInClick}
            />
          </Box>
        </Box>
      </div>
      <style>
        {`
          .scroll-link:hover {
            color: #ccd6f5 !important; /* Set color to #ccd6f5 on hover */
          }
          
          .scroll-link {
            font-weight: bold; /* Set text to bold */
          }
        `}
      </style>
    </ThemeProvider>
  );
}
